import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Usattalladega from '../assets/images/usattalladega.jpg';
import Baileywithvampire from '../assets/images/baileywithvampire.jpg';
import Kevinpatrickpouringsurup from '../assets/images/kevinpatrickpouringsurup.jpg';
import Abbyhollonabbiewithmug from '../assets/images/abbyhollonabbiewithmug.jpg';
import Poohvaleriekevinbretteatingwaffle from '../assets/images/poohvaleriekevinbretteatingwaffle.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Talladega: 2-17-01" />
      <div>
        <TripHeader>
          <h2>Talladega</h2>
          <p>2-17-01</p>
          <img
            src={Usattalladega}
            alt="Patrick, Pooh, Valerie, Trey, Abby, Brett, Bailey, Hollon, Jeeves, Abby, Kevin, and Justin B. at the Talladega Huddle House"
          />
          <ImageCaption>
            Patrick, Pooh, Valerie, Trey, Abby, Brett, Bailey, Hollon, Jeeves,
            Abby, Kevin, and Justin B. at the Talladega Huddle House
          </ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Baileywithvampire} alt="Bailey with Vampire" />
            <ImageCaption>Bailey with Vampire</ImageCaption>
          </div>
          <div>
            <img
              src={Kevinpatrickpouringsurup}
              alt="Kevin & Patrick Pouring Syrup"
            />
            <ImageCaption>Kevin & Patrick Pouring Syrup</ImageCaption>
          </div>
          <div>
            <img
              src={Abbyhollonabbiewithmug}
              alt="Abby, Hollon, & Abby With HH Mug"
            />
            <ImageCaption>Abby, Hollon, & Abby With HH Mug</ImageCaption>
          </div>
        </ImageGrid>
        <p>
          We did it again! Patrick, Pooh, Valerie, Trey, Abby, Brett, Bailey,
          Hollon, Jeeves, Abby, Kevin, and Justin B. went this time. We all
          loaded up and went to the Huddle House in Talladega after a party, and
          it was fun. I don't really recommend the Talladega Huddle House though
          because they just weren't as friendly as we are used to. It could have
          been that they were just busy though, so I shouldn't say anything
          because it was my one and only time to go there. It was fun though, we
          meet Vampire a regular in the area and an all out Huddle House fan. He
          has been all over and knew several regulars that we have meet such as
          the Holy Riders from last night. He was very nice and we all enjoyed
          chatting with him. We took Abby Moorehead, Hollon McKay, Abby Thomas,
          Jeremy "Jeeves" Butler, Valerie Deason, Trey Burroughs, and Justin
          Brown for their first time, making them all members. We are finally
          getting all those people taken who want to go, although we have many
          more to go. Bailey got a Huddle House mug to add to the collection of
          Huddle House stuff we have. We had an all around good time and some
          darn good pie as well!
        </p>
        <ImageGrid>
          <div>
            <img
              src={Poohvaleriekevinbretteatingwaffle}
              alt="Pooh, Valerie, Kevin & Brett"
            />
            <ImageCaption>Pooh, Valerie, Kevin & Brett</ImageCaption>
          </div>
        </ImageGrid>
      </div>
    </>
  );
}
